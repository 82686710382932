import React from "react";


import {Button} from "antd";


import Backstage from './backstage/backstage';
import BI from './bi/bi';
import ProjectPage from './project/project';


export default class Home extends React.Component {


  state = {
    herfEarth: '',
    status: null,
    childBtn: [{name: '后台管理', show: true, index: 0}, {name: 'BI管理', show: true, index: 1}]
  }


  componentWillMount () {
    let childBtn = [];
    try {
    childBtn = React.$getStorage('menuchildBtn');
    childBtn = childBtn?childBtn: [];
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
      }
    }
    } catch(e) {

    }
    


  this.setState({
    childBtn: childBtn
  })

    let t =  React.$getStorage('jurIndexTunnel')
    if(t) {
        this.setState({
            status: t
        })
    }
 }

 // 组件离开生命周期
 componentWillUnmount() {
    localStorage.removeItem('jurIndexTunnel');
  }

  componentDidMount() {
    
   
  }


  changeStatus(type) {
    this.setState({
        status: type
    })

    React.$setStorage('jurIndexTunnel', type) 

  }



  render() {
    return (
      <div className="Jurisdiction">
          <div className="btn" style={{paddingLeft: 10}}>
          {
              // eslint-disable-next-line array-callback-return
              this.state.childBtn.map((item, index) => {
                  if(item.show) {
                      return (
                          <Button key={index}  type={this.state.status===item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                          ) 
                  }
                  
              })
          }

          </div>

        <div>
            {
                function() {
                    if(this.state.status === 0) {
                        return (
                            <Backstage/>
                        )
                    }

                    
                    if(this.state.status === 1) {
                        return (
                            <BI/>
                        )
                    }

                    if(this.state.status === 2) {
                        return (
                            <ProjectPage />
                        )
                    }

                   
                }.bind(this)()
            
            }
        </div>





      </div>
    );
  }
}
