

import {  getStorage } from '@/utils/storage'
// import Icon from '@ant-design/icons';
//  import Gcqj from '@/assets/img/newReviseImg/imgAndIcon/gcqj.svg';
//  import Qjjk from '@/assets/img/newReviseImg/imgAndIcon/path1.svg';
//  import Ryjg from '@/assets/img/newReviseImg/imgAndIcon/path2.svg';
//  import Stjg from '@/assets/img/newReviseImg/imgAndIcon/path3.svg';
//  import Zhdj from '@/assets/img/newReviseImg/imgAndIcon/path4.svg';
//  import Aqjg from '@/assets/img/newReviseImg/imgAndIcon/anquan.svg';
//  import Zljg from '@/assets/img/newReviseImg/imgAndIcon/path5.svg';
//  import Sbjg from '@/assets/img/newReviseImg/imgAndIcon/path6.svg';
//  import Tzjg from '@/assets/img/newReviseImg/imgAndIcon/path7.svg';
//  import Aizn from '@/assets/img/newReviseImg/imgAndIcon/path8.svg';
//  import Xtbg from '@/assets/img/newReviseImg/imgAndIcon/path9.svg';
//  import Bhez from '@/assets/img/newReviseImg/imgAndIcon/path10.svg';
//  import Pz from '@/assets/img/newReviseImg/imgAndIcon/path11.svg';




let projectInfo = 2
try {
  projectInfo=getStorage('projectInfo').mixingStation
}catch{
  
}



let status = true
if(projectInfo == 2) {
  status = false
} else {
  status = true
}


const menuList = [{
  "name": "工程全景",
  "show": true,
  "English": "Project panorama",
  "icon": "https://project.1wx.cn/imgAndIcon/gcqj.svg",
  "children": [{
    "name": "720全景",
    "path": "/panorama/720panorama",
    "show": true
  }, {
    "name": "全景管理",
    "path": "/panorama/projectSummary",
    "show": true
  }]
}, {
  "name": "实时监控",
  "show": true,
  "English": "Panoramic monitoring",
  "icon": "https://project.1wx.cn/imgAndIcon/path1.svg",
  "children": [{
    "name": "视频监控",
    "path": "/PanoramicMonitoring/videoSurveillance",
    "show": true
  }, {
    "name": "监控管理",
    "path": "/PanoramicMonitoring/monitoringManagement",
    "show": true
  }]
}, {
  "name": "人员监管",
  "show": true,
  "English": "Personnel supervision",
  "icon": "https://project.1wx.cn/imgAndIcon/path2.svg",
  "children": [{
    "name": "人员概览",
    "path": "/PersonnelSupervision/Overview",
    "show": true
  }, {
    "name": "花名册管理",
    "path": "/PersonnelSupervision/roster",
    "show": true,
    "childBtn": [{
      "name": "人证已审核",
      "index": 0,
      "show": true
    }, {
      "name": "人证待审核",
      "index": 1,
      "show": true
    }]
  }, {
    "name": "考勤管理",
    "path": "/PersonnelSupervision/Attendance",
    "show": true
  }, {
    "name": "工资监管",
    "path": "/PersonnelSupervision/Wages",
    "show": true,
    "childBtn": [{
      "name": "工资统计",
      "index": 0,
      "show": true
    }, {
      "name": "工资录入",
      "index": 1,
      "show": true
    }]
  }, {
    "name": "人员证照",
    "path": "/PersonnelSupervision/Certificates",
    "show": true,
    "childBtn": [{
      "name": "证件统计",
      "index": 0,
      "show": true
    }, {
      "name": "证件查询",
      "index": 1,
      "show": true
    }]
  },
  {
    "name":"企业级用户列表",
    "path": "/PersonnelSupervision/EnterpriseLevelUsers",
    "show": true,
  },
  {
    "name": "劳务积分考评",
    "path": "/PersonnelSupervision/ServicePoints",
    "show": true,
    "childBtn": [{
      "name": "考评汇总",
      "index": 0,
      "show": true
    }, {
      "name": "考评查询",
      "index": 1,
      "show": true
    }, {
      "name": "考评规则",
      "index": 2,
      "show": true
    }]
  }]
}, {
  "name": "绿色施工",
  "show": true,
  "English": "Ecological supervision",
  "icon": "https://project.1wx.cn/imgAndIcon/path3.svg",
  "children": [{
    "name": "环保监测",
    "path": "/EcologicalSupervision/environmentalMonitoring",
    "show": true,
    "childBtn": [{
      "name": "监测概览",
      "index": 0,
      "show": true
    }, {
      "name": "监测明细",
      "index": 1,
      "show": true
    }, {
      "name": "监测数据",
      "index": 2,
      "show": true
    }]
  }, {
    "name": "水保监测",
    "path": "/EcologicalSupervision/WaterConservation",
    "show": true
  }, 
  {
    "name": "水位监测",
    "path": "/EquipmentSupervision/waterLevel",
    "show": true
  }, {
    "name": "防汛监测",
    "path": "/EcologicalSupervision/FloodPrevention",
    "show": true
  }, {
    "name": "气体监测",
    "path": "/EcologicalSupervision/Gas",
    "show": true
  }]
}, {
  "name": "智慧党建",
  "show": true,
  "English": "Smart Party",
  "icon": "https://project.1wx.cn/imgAndIcon/path4.svg",
  "children": [{
    "name": "主页",
    "path": "/WisdomParty/Home",
    "show": true
  }, {
    "name": "党建活动",
    "path": "/WisdomParty/partyActive",
    "show": true
  }, {
    "name": "会议协商",
    "path": "/WisdomParty/consult",
    "show": true
  }]
}, {
  "name": "安全监管",
  "English": "Safety supervision",
  "show": true,
  "icon": "https://project.1wx.cn/imgAndIcon/anquan.svg",
  "children": [{
    "name": "隐患排查",
    "path": "/SecurityInvestigation",
    "show": true,
    "childBtn": [{
      "name": "排查概览",
      "index": 0,
      "show": true
    }, {
      "name": "排查记录",
      "index": 1,
      "show": true
    }]
  }, {
    "name": "行为管理",
    "path": "/SecurityInvestigation/BehaviorManagement",
    "show": true,
    "childBtn": [{
      "name": "行为记录",
      "index": 0,
      "show": true
    }, {
      "name": "奖罚设置",
      "index": 1,
      "show": true
    }]
  }, {
    "name": "安全教育",
    "path": "/SafeEducation/safeEducation",
    "show": true,
    "childBtn": [{
      "name": "教育概览",
      "index": 0,
      "show": true
    }, {
      "name": "教育记录",
      "index": 1,
      "show": true
    }, {
      "name": "考试记录",
      "index": 2,
      "show": true
    }, {
      "name": "题录管理",
      "index": 3,
      "show": true
    }]
  }, {
    "name": "应急管理",
    "path": "/SafeEducation/emergencyManagement",
    "show": true
  }]
}, {
  "name": "生产管理",
  "show": true,
  "English": "Quality Supervision",
  "icon": "https://project.1wx.cn/imgAndIcon/path5.svg",
  "children": [{
    "name": "施工进展",
    "path": "/QualitySupervision/ConstructionProgress",
    "show": true,
    "childBtn": [{
      "name": "总体施工进度",
      "index": 4,
      "show": true
    }, {
      "name": "隧洞",
      "index": 0,
      "show": true
    }, {
      "name": "洞室",
      "index": 1,
      "show": true
    }, {
      "name": "引水渠、进水箱涵",
      "index": 2,
      "show": true
    }, {
      "name": "场内道路",
      "index": 3,
      "show": true
    }]
  }, {
    "name": "隧道管理",
    "path": "/SPRO/Tunnel",
    "show": true,
    "childBtn": [{
      "name": "隧道列表",
      "index": 0,
      "show": true
    }, {
      "name": "围岩列表",
      "index": 1,
      "show": true
    }]
  }]
}, {
  "name": "设备监管",
  "show": true,
  "English": "Equipment supervision",
  "icon": "https://project.1wx.cn/imgAndIcon/path6.svg",
  "children": [{
    "name": "设备巡检",
    "path": "/EquipmentSupervision/deviceInspectionDeviceList",
    "show": true
  }, {
    "name": "车辆进出",
    "path": "/EquipmentSupervision/vehicleControlList",
    "show": true,
    "childBtn": [{
      "name": "车辆进出",
      "index": 0,
      "show": true
    }, {
      "name": "车辆进出日志",
      "index": 1,
      "show": true
    }, {
      "name": "车辆授权信息",
      "index": 2,
      "show": true
    }, {
      "name": "车辆列表",
      "index": 3,
      "show": true
    }]
  }, {
    "name": "电箱监测",
    "path": "/EquipmentSupervision/monitorLogList",
    "show": true
  }, {
    "name": "车辆定位",
    "path": "/EquipmentSupervision/VehiclePositioning",
    "show": true,
    "childBtn": [{
      "name": "实时监控",
      "index": 0,
      "show": true
    }, {
      "name": "轨迹查询",
      "index": 1,
      "show": true
    }]
  },
  {
    "name": "塔吊检测",
    "path": "/EquipmentSupervision/Tower",
    "show": false
  }]
}, {
  "name": "技术管理",
  "show": true,
  "English": "Drawing management",
  "icon": "https://project.1wx.cn/imgAndIcon/path7.svg",
  "children": [{
    "name": "图纸列表",
    "path": "/Drawing/DrawingList",
    "show": true
  }, {
    "name": "图纸管理",
    "path": "/Drawing/DrawingManage",
    "show": true
  }]
}, {
  "name": "智慧监管",
  "show": true,
  "English": "Intelligent management",
  "icon": "https://project.1wx.cn/imgAndIcon/path8.svg",
  "children": [{
    "name": "AI钢筋识别",
    "path": "/EquipmentSupervision/AIRebar",
    "show": true,
    "childBtn": [{
      "name": "验收概览",
      "index": 0,
      "show": true
    }, {
      "name": "验收记录",
      "index": 1,
      "show": true
    }]
  }]
}, {
  "name": "协同办公",
  "show": true,
  "English": "Collaborative office",
  "icon": "https://project.1wx.cn/imgAndIcon/path9.svg",
  "children": [{
    "name": "会议签到",
    "path": "/CollaborativeOffice/MeetingSignIn",
    "show": true
  }, {
    "name": "入场公告",
    "path": "/CollaborativeOffice/Notice",
    "show": true
  }, {
    "name": "新闻列表",
    "path": "/projectSummary/newsList",
    "show": true
  }]
}, {
  "name": "拌合站监测",
  "show": true,
  "English": "Factory monitoring",
  "icon": "https://project.1wx.cn/imgAndIcon/path10.svg",
  "children": [{
    "name": "拌合站概览",
    "path": "/QualitySupervision/mixingStationOverview",
    "show": false
  }, {
    "name": "BI数据看板",
    "path": "/MixingPlant/BIDataKanban",
    "show": true
  }, {
    "name": "任务单",
    "path": "/MixingPlant/TaskList",
    "show": true
  }, {
    "name": "砼生产情况和运输记录",
    "path": "/MixingPlant/ProductionTransportation",
    "show": true
  }, {
    "name": "账单/(日、月)",
    "path": "/MixingPlant/MixingBill",
    "show": true
  }, {
    "name": "发货单查询",
    "path": "/MixingPlant/Invoice",
    "show": true
  }, {
    "name": "配合比查询",
    "path": "/MixingPlant/MixSerch",
    "show": true
  }]
}, {
  "name": "云开仓",
  "show": true,
  "English": "Cloud opening",
  "icon": "https://oss.1wx.cn/weChatApplets/coordination/openAPosition.svg",
  "children": [{
    "name": "准浇证",
    "path": "/CloudOpening/CastingPermit",
    "show": true
  }, {
    "name": "下料单",
    "path": "/CloudOpening/BlankingList",
    "show": true
  }, {
    "name": "施工日志",
    "path": "/CloudOpening/ConstructionLog",
    "show": true
  }, {
    "name": "理论配合比",
    "path": "/CloudOpening/MixProportion",
    "show": true
  }, {
    "name": "项目划分",
    "path": "/CloudOpening/engineering",
    "show": true
  }, {
    "name": "产地规格",
    "path": "/CloudOpening/OriginSpecification",
    "show": true
  }]
}, {
  "name": "后台管理",
  "show": true,
  "English": "Pro management",
  "icon": "https://project.1wx.cn/imgAndIcon/path11.svg",
  "children": [{
    "name": "功能设置",
    "path": "/SPRO/useConfig",
    "show": true,
    "childBtn": [{
      "name": "后台管理",
      "show": true,
      "index": 0
    }, {
      "name": "BI管理",
      "show": true,
      "index": 1
    },
    {
      "name": "项目管理",
      "show": true,
      "index": 2
    }]
  }, {
    "name": "项目配置",
    "path": "/SPRO/ProjectConfig",
    "show": true,
    "childBtn": [{
      "name": "项目概览",
      "index": 0,
      "show": true
    }, {
      "name": "进度节点",
      "index": 1,
      "show": true
    }, {
      "name": "组织架构",
      "index": 2,
      "show": true
    }]
  }, {
    "name": "Banner配置",
    "path": "/SPRO/BannerConfig",
    "show": true,
    "childBtn": [{
      "name": "pc端",
      "index": 0,
      "show": true
    }, {
      "name": "移动端",
      "index": 1,
      "show": true
    }]
  }, {
    "name": "权限管理",
    "path": "/SPRO/Jurisdiction",
    "show": true,
    "childBtn": [{
      "name": "管理列表",
      "index": 0,
      "show": false
    }, {
      "name": "权限列表",
      "index": 1,
      "show": true
    }, {
      "name": "角色权限",
      "index": 2,
      "show": true
    }]
  },
  {
    "name": "积分兑换超市",
    "path": "/EquipmentSupervision/machineDeviceAll",
    "show": true,
    "childBtn": [{
      "name": "积分兑换",
      "index": 0,
      "show": true
    }, {
      "name": "售货机商品",
      "index": 1,
      "show": true
    }, {
      "name": "售货机授权日志",
      "index": 2,
      "show": true
    }, {
      "name": "售货机设备",
      "index": 3,
      "show": true
    }]
  }, {
    "name": "人脸机",
    "path": "/EquipmentSupervision/distinguish",
    "show": true,
    "childBtn": [{
      "name": "识别列表",
      "index": 0,
      "show": true
    }, {
      "name": "识别日志列表",
      "index": 1,
      "show": true
    }, {
      "name": "授权日志列表",
      "index": 2,
      "show": true
    }, {
      "name": "设备列表",
      "index": 3,
      "show": true
    }]
  },]
}, {
  "name": "404",
  "path": "/error",
  "show": false
}];
export default menuList;