


import React, { useState, useEffect, useReducer } from "react";
import { withRouter } from 'react-router-dom';
import {Modal, Form, Button, message, Input, Popconfirm} from 'antd';

import Style from './project.module.less';
import {ArrowUpOutlined, ArrowDownOutlined} from '@ant-design/icons';

let sbmitList = [];

function ProjectPage() {
    // let infoParams = {};
    const [, forceRerender] = useReducer(x => x + 1, 0);
    const [menuList, setMenuList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [params, setParams] = useState({});
    const [form] = Form.useForm();
    
    useEffect(() => {
        getList();
    }, []) 

    const getList = () => {
        React.$axios.post('projectWhereUser',).then(res => {
            setMenuList(res.data.projectWhereUser);
        })
    }



    // 向上移动
    const toUp = (index) => {

        if(index === 0) {
            return;
        }

        let upItem = menuList[index - 1];
        let current = menuList[index];

        menuList[index] = upItem;
        menuList[index - 1] = current;

        setMenuList(menuList);

        forceRerender();

    }
    
    // 向下移动
    const toDown = (index) => {
      if(index === menuList.length - 1) {
        return;
      }  

      let downItem = menuList[index + 1];
      let current = menuList[index];

      menuList[index] = downItem;
      menuList[index + 1] = current;
      setMenuList(menuList);
      forceRerender();

    }

    // 去提交
    const toSbmit = () => {
        let submitArr = [];
        menuList.forEach((item, index) => {
            submitArr.push({
                name: item.nameProject,
                id: item.projectId,
                weigh: menuList.length - index
            });
        });

        React.$axios.post('projectEditAllSave', submitArr).then(res => {
            if(res.code === 20000) {
                message.success('保存成功');
                getList();
            }
        })
    }

    // 去删除
    const toDelete = (item) => {
        console.log(item);
    }

    // 改变值
    const tochangeVal = (e) => {
        let value = e.target.value;
        params.nameProject = value;
        setParams(params);
        forceRerender();


    }

    // 去提交单个项目
    const toSbmitProject = () => {
        toSbmit();
        setIsModalVisible(false);
    }

    const  renderMenu = (menuList) => {

        return menuList.map((item, index) => {
            return (  
            <div key={item.projectId} className="li" style={{paddingLeft: 25 + 'px'}}>
                <div className="setWidth" >
                    <div className="flex">
                        <ArrowUpOutlined onClick={(e) => {e.stopPropagation();  toUp(index)}}></ArrowUpOutlined>
                        <ArrowDownOutlined onClick={(e) => {e.stopPropagation(); toDown(index)}}></ArrowDownOutlined>
                        {/* <img src={item.icon}></img> */}
                        <b>{item.nameProject}</b>  
                    </div>


                    <div className="control">
                        <p className="edit" onClick={() => {
                          setIsModalVisible(true);
                          setParams(item);
                            // setProjectName(item.nameProject);
                        }}>编辑</p>

                        {/* <Popconfirm
                            title="删除"
                            description="是否删除项目?"
                            onConfirm={() => {toDelete(item)}}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <p className="delete">删除</p>
                        </Popconfirm> */}
                    </div> 

                </div>
            </div>
            );

        });
      };

    return (
        <div className={Style.ProjectPage}>
            <div className="UseConfigTitle">
                <p>项目名称</p>
                <p className="control">操作</p>
            </div>
            <div className='pos'>
                <div className="useConfigUl">
                    {renderMenu(menuList)}
                </div>
            </div>

            <p className="toSbmit">
            <Button  type='primary' onClick={() => toSbmit()}><span className="setPadding">提交</span></Button>
            </p>
            


        <Modal title="编辑" 
            width="400px"
            okText="确定"
            cancelText="取消"
            visible={isModalVisible} 
            onOk={toSbmitProject} 
            onCancel={() => {setIsModalVisible(false)}}
            >
            <Form 
            layout="vertical"
            form={form}

            >
            <Form.Item  label="项目名称">
                <Input placeholder="请输入项目名称"  value={params.nameProject} onChange={tochangeVal} />
            </Form.Item>
                
                
            </Form>  
        </Modal>    
        </div>
    )

}


export default withRouter(ProjectPage);