import React, { Component } from 'react';
import { Form, Input, Button, Radio, Checkbox, message} from 'antd';
import { connect } from 'react-redux';
import { setProjectInfo } from '@/redux/actions/projectInfo';


import './login.less';
import video from '@/assets/img/newReviseImg/imgAndIcon/H264.mp4';
import icon from '@/assets/img/newReviseImg/imgAndIcon/logo2.png';
import titleImg from '@/assets/img/newReviseImg/imgAndIcon/logo1.png';
import backg from './1.jpg';
import { LoadingOutlined } from '@ant-design/icons';


const FormItem = Form.Item;
class Login extends Component {
  imgUrl = process.env.REACT_APP_IMG_URL
  state = {
    name: '',
    password: '',
    loginType: 2,

    Checkbox: true,
    infoConfig: {},
    showUser: false,

    projectActive: true,
    phoneTime: 0,
    tipsMsg: true,
    code: '', // 验证码
    showImg: true,
  }
  toLogin(e) {
    let loginType = null;
    let requestData = {
      name: this.state.name,
    }
    if(this.state.projectActive) { // 项目级
      if(this.state.loginType == 2) { // 用户
        loginType = 2
        // requestData.code = this.state.code;
        requestData.password = this.state.password;
      } else if(this.state.loginType == 3) {// 项目管理
        loginType = 3
        requestData.password = this.state.password;
      }
    } else { // 企业级
      if(this.state.loginType == 2) { // 用户
        loginType = 10
        // requestData.code = this.state.code;

        requestData.password = this.state.password;
      } else if(this.state.loginType == 3) {// 项目管理
        loginType = 8
        requestData.password = this.state.password;
      }
    }

    requestData.loginType = loginType;

    
    React.$axios.post('login', requestData).then(({ data }) => {
      React.$setStorage('accessLogin', data.accessLogin)
      React.$setStorage('loginInfo', data.adminInfoMe)
      React.$axios.post('infoProject').then(({ data }) => {
        
        
        React.$setStorage('projectInfo', data.infoProject[0])
        this.props.setProjectInfo(data.infoProject[0])
        this.props.history.push('/HomeRouter')
      })
    })
  };
  change(name,e) {
    this.setState({
      [name]: e.target.value
    })
  }

  componentWillMount () {

    // 时间验证
    let  yanzTime = React.$getStorage('currentTime')
    if(yanzTime) {
      let time = new Date().getTime();
      let currentTime = parseInt((time - (yanzTime + 30000)) / 1000);
      
      if(currentTime < 0) {
        this.toSetTime(Math.abs(currentTime))
      } else {
        React.$removeStorage('currentTime');  
      }
    }

     
    if(window.location.search) {
      let str = window.location.search.split('=')

      if(str[1] == 'admin') {
        this.setState({
          showUser: true
        })
      }
    }

    try {
      let LoginMsg = React.$getStorage('LoginMsg')
      if(LoginMsg) {
        this.setState({
          name: LoginMsg.name,
          password: LoginMsg.password,
          loginType: 2,
          // loginType: LoginMsg.loginType?LoginMsg.loginType:2,
          projectActive: LoginMsg.projectActive
        })
      }

    } catch(e) {
      console.log(e)
    }
    
  }

  // 组件离开生命周期
  componentWillUnmount() {
    React.$setStorage('LoginMsg', {
      name: this.state.name,
      password: this.state.password,
      loginType: this.state.loginType,
      projectActive: this.state.projectActive
    })
    //  if(this.state.Checkbox) {
    //   React.$setStorage('LoginMsg', {
    //     name: this.state.name,
    //     password: this.state.password,
    //     loginType: this.state.loginType,
    //     projectActive: this.state.projectActive
    //   })
    //  } else {
    //   React.$setStorage('LoginMsg', {
    //     name: null,
    //     password: null,
    //     loginType: null
    //   })
    //  }


  }



  componentDidMount() {
    React.$axios.post('infoConfig').then(res => {
      this.setState({
        infoConfig: res.data.infoConfig
      })

    })
  }

  onChangeRadio(val) {
    this.setState({
      loginType: val.target.value
    })

  }

  onChangeCheckbox(val) {
    this.setState({
      Checkbox: !this.state.Checkbox
    })

  }

  // 选择用户信息
  selectUser(val) {
    this.setState({
      loginType: val
    })

      // 切换更新本地数据
      React.$setStorage('LoginMsg', {
        name: this.state.name,
        password: this.state.password,
        loginType: val,
        projectActive: this.state.projectActive
      })
  }

  // 项目切换登录
  onChangeProjectActive(val) {
    this.setState({
      projectActive: val
    })

    // 切换更新本地数据
    React.$setStorage('LoginMsg', {
      name: this.state.name,
      password: this.state.password,
      loginType: this.state.loginType,
      projectActive: val
    })
  }

  getPhoneMsg() {
   
    React.$axios.post('verificationCode', {phone: this.state.name}).then(res => {
      if(res.data) {
        message.success('获取验证码成功')
        let date = new Date().getTime();
        React.$setStorage('currentTime', date);
        this.toSetTime(30)
      }

    })

  }

  // 设置时间
  toSetTime(val) {
    this.setState({
      phoneTime: val,
      tipsMsg: false
    })
    
    let timeout =  setInterval(() => {
      val -= 1;
      
      this.setState({
        phoneTime: val
      })
      
      if(val == 0) {
        clearInterval(timeout)
        React.$removeStorage('currentTime'); 
      }
    }, 1000)
  }

  toCloseImg() {
    this.setState({
      showImg: false
    })
  }


  
  render() {
    return (
      <div className="Login">

        {/* <div className='LoginBack' style={{display: this.state.showImg?' ': 'none'}}>
          <img  src={backg} alt="" />


          <div className="wanxi-wrap" >
              <div>本系统暂停使用</div> 
              <p onClick={this.toCloseImg.bind(this)}>© 广州万玺科技有限公司 提供技术支持</p>
              <p>粤ICP备19015884号</p>
            </div>
        </div> */}


        <video className='video' autoplay="autoplay" loop="loop" muted="muted"   src={video}></video>

        <div className="positionRight">
          {/* <div className='icon'>
            <img src={icon} alt="" />
          </div> */}

          

          <div className='loginWarp'>
            {/* <img src={titleImg} alt="" /> */}
            <div className='projectDes'>
              {/* <p className='china'>企业级工程数字管理系统</p> 
              <p className='english'>ENTERPRISE DIGITAL MANAGEMENT SYSTEM</p> */}

              <p className='china'>土木工程公司</p> 
              <p className='english'>BAM NUTTALL</p>
            </div>

            <div className='selectAdmin'>
              <div onClick={this.onChangeProjectActive.bind(this, true)} className={this.state.projectActive?'active':''}>项目级登录</div>
              <div className='cube' style={{transform:this.state.projectActive?'skewX(20deg)':'skewX(-20deg)'}}></div>
              <div onClick={this.onChangeProjectActive.bind(this, false)} className={this.state.projectActive?'':'active'}>企业级登录</div>
            </div>
            <div className="from">
                
            {/* <div className="radio">
            <Radio.Group onChange={this.onChangeRadio.bind(this)} value={this.state.loginType}>
              <Radio value={3}><span className={this.state.loginType == 3? 'setSpan': ''}>管理员登录</span> </Radio>
              {
                function() {
                  if(this.state.showUser) {
                    return (
                      <Radio value={2}><span className={this.state.loginType == 2? 'setSpan': ''}>用户登录</span> </Radio>
                    )
                  } else {
                    return
                  }
                 
                }.bind(this)()
              }
              
            </Radio.Group>
            </div> */}

            <Form className="login-form">
              <ul>
                <li>
                  <div className='title'>角色</div>

                  <div className='selectUser'>
                    <div>
                      <span onClick={this.selectUser.bind(this, 2)} className={this.state.loginType == 2? 'active': ''}></span>
                      <p>{this.state.projectActive? '用户': '手机'} </p>
                    </div>
                    <div>
                      <span onClick={this.selectUser.bind(this, 3)} className={this.state.loginType == 3? 'active': ''}></span>
                    
                      <p>{this.state.projectActive? '项目管理员': `账号`} </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='title'>账号</div>
                  <FormItem>
                    <input type="text" value={this.state.name} className='seOpctiy0'  onInput={this.change.bind(this, 'name')}  style={{backgroundColor: 'rgba(233,233,233, 0.8)'}} placeholder="用户名"  /> 
                  </FormItem>
                </li>
                <li>
                  <div className='title'>密码</div>
                  <FormItem>

                    {
                      function() {

                        return (
                          <input type="password" value={this.state.password} className='seOpctiy0' onInput={this.change.bind(this, 'password')}  style={{backgroundColor: 'rgba(233,233,233, 0.8)'}} placeholder="密码" />
                        )

                        // if(this.state.loginType == 2 && this.state.projectActive == false) {
                        //   return (
                        //     <>
                        //     <input type="text" value={this.state.code} className='seOpctiy0' onInput={this.change.bind(this, 'code')}  style={{backgroundColor: 'rgba(233,233,233, 0.8)'}} placeholder="验证码" />
                        //     <button style={{display: this.state.loginType == 2?'':'none'}} className='getPhoneMsg' disabled={this.state.phoneTime?true:false} onClick={this.getPhoneMsg.bind(this)}><LoadingOutlined style={{display: this.state.phoneTime?'':'none'}} /> {this.state.phoneTime?this.state.phoneTime:this.state.tipsMsg?'获取验证码':'重新获取'}</button>
                            
                        //     </>
                        //   ) 
                        // } else {
                        //   return (
                        //     <input type="password" value={this.state.password} className='seOpctiy0' onInput={this.change.bind(this, 'password')}  style={{backgroundColor: 'rgba(233,233,233, 0.8)'}} placeholder="密码" />
                        //   )
                        // }
                      }.bind(this)()
                    }

                    {/* <input style={{display: this.state.loginType == 3?'':'none', backgroundColor: 'rgba(233,233,233, 0.8)'}} type="password" value={this.state.password} className='seOpctiy0' onInput={this.change.bind(this, 'password')}   placeholder="密码" />
                    
                    <input style={{display: this.state.loginType == 2?'':'none', backgroundColor: 'rgba(233,233,233, 0.8)'}} type="text" value={this.state.code} className='seOpctiy0' onInput={this.change.bind(this, 'code')}  placeholder="验证码" />

                    
                   */}
                  </FormItem>
                </li>
              </ul>



              
              

              <FormItem>
                <div className="setSubmit">
                <Button  className="submit" htmlType="submit"  block onClick={(e) => { this.toLogin(e) }}>立即登录</Button>
                </div>
              </FormItem>
            </Form>
          </div>


           
          </div>

          <div className="wanxi-wrap">
              <p>© 广州万玺科技有限公司 提供技术支持</p>
              <a href="https://beian.miit.gov.cn/">粤ICP备19015884号</a>
              <a href="https://xyt.xcc.cn/getpcInfo?sn=1485902562060468224&certType=8&url=*.1wx.cn&language=CN" style={{position: 'relative', left: '10px', top: '3px'}}><img src="https://program.xinchacha.com/xyt/xcc_small_ssl.png" style={{width:'20px', height:'20px'}} /></a>
            </div>

          

          {/* <div className="from">
            <h1>登录</h1>
    
            <div className="radio">
            <Radio.Group onChange={this.onChangeRadio.bind(this)} value={this.state.loginType}>
              <Radio value={3}><span className={this.state.loginType == 3? 'setSpan': ''}>管理员登录</span> </Radio>
              {
                function() {
                  if(this.state.showUser) {
                    return (
                      <Radio value={2}><span className={this.state.loginType == 2? 'setSpan': ''}>用户登录</span> </Radio>
                    )
                  } else {
                    return
                  }
                 
                }.bind(this)()
              }
              
            </Radio.Group>
            </div>

            <Form className="login-form">
              <FormItem>
                <input type="text" value={this.state.name} className='seOpctiy0'  onInput={this.change.bind(this, 'name')}  style={{backgroundColor: 'transparent'}} placeholder="用户名"  /> 
              </FormItem>
              <FormItem>
                <input type="password" value={this.state.password} className='seOpctiy0' onInput={this.change.bind(this, 'password')} style={{backgroundColor: 'transparent'}} placeholder="密码" />
              </FormItem>

              <FormItem>
                <div className="setSubmit">
                <Button type="primary" className="submit" htmlType="submit"  block onClick={(e) => { this.toLogin(e) }}>立即登录</Button>
                </div>
              </FormItem>
            </Form>
          </div> */}


        </div>

      </div>
    );
  }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  setProjectInfo: data => {
    dispatch(setProjectInfo(data));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
