import React from "react";


import { Input,Button, Table, message, Popconfirm, Modal, Form, Checkbox} from "antd";
import { SearchOutlined, PlusOutlined} from '@ant-design/icons';
import Style from './EnterpriseLevelUsers.module.less';




const layout = {
    labelCol: { span: 4 },
};

export default class  PartyActive extends React.Component {
    
    columns = [
      {
        title: "姓名",
        dataIndex: 'name',
        align: 'center',
        width: 200
      },
        {
            title: '手机号码',
            dataIndex: 'phone',
            align: 'center',
         },
        {
          title: '身份证号码',
          dataIndex: 'number',
          align: 'center',
        },

        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            width: 80,
            render:(_,item) => {
                return (
                    <div style={{display: 'flex', width: 80}}>
                  
                        <div onClick={this.getDetail.bind(this, item)} style={{color: '#6A9BF4', cursor:'pointer' , marginRight: 20}}>编辑</div>
                        <Popconfirm
                            title="是否确认删除?"
                            onConfirm={this.toDel.bind(this, item.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                          <p style={{color: '#ff0000 ', cursor:'pointer'}}>删除</p>
                        </Popconfirm>
                    </div>
                    
                    
                )
            }
        },
      ]

  FormSbmitRef = ''  
  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 1,
        keyword: null
    },

    infoMsg: {
      signIn: [],
      singUp: []
    }, // 详情信息
    isModalVisible: false,
    detailItem: {
      img: []
    },
    projectList: [],
    isDetailShow: false,
    FormParams: {
        name: null,
        phone: null,
        number: null,
        projectIdPermission: [],
        id: null
    },
  }


  




  componentDidMount() {
    this.getTableData()
    
    this.getProjectAll();
  }


  // 获取项目所有
  getProjectAll() {
    React.$axios.post('projectAll', {logic: 2}).then(res => {

        this.setState({
            projectList: res.data.projectAll
        })

        
    });
  }

  // 获取详情信息
  getDetail(item) {

   
    // console.log({...item, projectIdPermission: item.projectIdPermission.split(',')});


    this.setState({
        isModalVisible: true,
        FormParams: {...item, projectIdPermission: item.projectIdPermission.split(',')},
    }, () => {

   
        this.setFrom();
    })



  }


  setFrom() {

    this.FormSbmitRef.resetFields()

  }



  


  getTableData(type) {
       if(type) {
           // eslint-disable-next-line react/no-direct-mutation-state
           this.state.TableParams.offsetLimit = 0
           // eslint-disable-next-line react/no-direct-mutation-state
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

    //    let projectId =  React.$getStorage('projectInfo').id
      React.$axios.post('userListEnterprise', {...this.state.TableParams}).then(res => {
        
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.pagination.total = res.data.countList

        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    this.state.TableParams.keyword = value
  }

  toSerch2() {
    this.getTableData(true)
  }



  toDel(id) {
    React.$axios.post('userSaveEnterprise', {id, del: 2}).then(res => {
        if(res.code === 20000) {
            message.success('删除成功')
            this.getTableData()
        } else {
            message.error('删除失败')
        }
    })
  }

  addData() {
    this.setState({
        isModalVisible: true,
        FormParams: {
            name: null,
            phone: null,
            number: null,
            projectIdPermission: [],
            id: null
        },
    }, () => {

        this.setFrom();
    });


  }

  handleOk() {
    this.FormSbmitRef.submit();
  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

 
  handleTableChange(value) {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.pagination = value
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }


  onChangeT(name, value) {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.TableParams[name] = value;
    this.setState({
      TableParams: this.state.TableParams
    })
  }

   onFinish(value) {

      React.$axios.post('userSaveEnterprise', {id:this.state.FormParams.id ,...value, projectIdPermission: value.projectIdPermission.join(',')}).then(res => {
          if(res.code === 20000) {
              message.success('保存成功')
              this.handleCancel()
              this.getTableData(true)
              
          } 
      }) 

    }

  render() {
    return (
      <div className={Style.EnterpriseLevelUsers + " PartyActive"}>
          <div className="input">
                    <ul className={this.state.openStatus?'ul addOpen':'ul removeOpen' }>
                        <div className="left">
                            <li><span>模糊搜索:</span> 
                            <Input onChange={this.serchCar.bind(this, 'name')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="请输入身份证号码、姓名、手机号码" />
                            
                            </li>
                            <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>
                            
                        </div>
                        

                            <Button  className="bt" onClick={this.addData.bind(this)} type="primary" icon={<PlusOutlined />}>
                            新建
                        </Button>
                    </ul>

                    
                </div>



                <div className="table2">
             

                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                   
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>





                <Modal title={this.state.FormParams.id == null? '新增': '编辑'} width="60%" className={Style.gridModal} visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                                    <Form
                                        onFinish={this.onFinish.bind(this)}
                                        ref={(el) => this.FormSbmitRef = el}
                                        {...layout}
                                        initialValues = {this.state.FormParams}
                                    >
                                        <div className="grid_flex">
                                        

                                        <Form.Item label="姓名" name="name" rules={[{ required: true }]} >
                                        <Input placeholder="请输入姓名" />
                                        </Form.Item>

                                        <Form.Item label="手机号码" name="phone" rules={[{ required: true }]} >
                                        <Input placeholder="请输入手机号码" />
                                        </Form.Item>

                                        <Form.Item label="身份证号码" name="number" rules={[{ required: true }]} >
                                        <Input placeholder="请输入身份证号码" />
                                        </Form.Item>
                                    </div>


                                    <div className="table_flex">


                                    <Form.Item name="projectIdPermission" label="项目" labelCol={4}>
                                       
                                    <Checkbox.Group>
                                        <ul className="grid_flex grid_half">
                                            {this.state.projectList.map((item, index) => {

                                                if(item.id === 1) return (<></>);
                                                return (
                                                    <li key={index}>
                                                        <Checkbox value={`${item.id}`} style={{ lineHeight: '32px' }}>
                                                            {item.name}
                                                        </Checkbox>

                                                    </li>

                                                );
                                            })}


                                        </ul>
                                 


                                    </Checkbox.Group>



                                    </Form.Item>



                                    </div>

         
                                    </Form>

                    </Modal> 


     
                                                                                            
      </div>
    );
  }
}
